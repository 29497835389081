import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Events } from './events.service';
import { Observation } from '../interfaces/observation';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  private baseurl: string = "https://www.pollux.wwf.fr/admin"
  constructor(
    private readonly http: HttpClient,
    private evts: Events
  ) {
    if (!window.location.origin.includes('pollux.wwf.fr')) {
      this.baseurl = "https://www.behindyourclicks.org/pollux-back";
    }

    this.loadType('commerces');
    this.loadType('eclairages');
    this.loadType('reactions');
  }

  getJSON(args: any): Observable<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded');

      let stoken = sessionStorage.getItem('sessiontoken');
      if (stoken !== null) { args['stoken'] = stoken; }

    return this.http.post(`${this.baseurl}/api.php`, JSON.stringify(args), {'headers': headers});
  }

  reinitPwd(email: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded');

    let url = `${this.baseurl}/password_mail.php?login=${email}`;
    this.http.get(url, {'headers': headers}).subscribe((data) => {console.log(data)});
  }

  loadType(type: string) {
    this.getJSON({
      cmd: `${type}`
    }).subscribe((data) => {
      this.evts.publish(type, data);
    })
  }

  checkUser(login: string, hpwd: string): Observable<any> {
    return this.getJSON({
      cmd: `loginapp`,
      hpwd: `${hpwd}`,
      login: `${login}`
    });
  }

  getBoxObservations(brlon, brlat, tllon, tllat): Observable<any> {
    return this.getJSON({
      cmd: `box-observations`,
      brlon: brlon,
      brlat: brlat,
      tllon: tllon,
      tllat: tllat
    });
  }

  getBoxPendingObservations(brlon, brlat, tllon, tllat): Observable<any> {
    return this.getJSON({
      cmd: `box-pending-observations`,
      brlon: brlon,
      brlat: brlat,
      tllon: tllon,
      tllat: tllat
    });
  }

  getPictures(id: number): Observable<any> {
    return this.getJSON({
      cmd: `pictures`,
      id: `${id}`
    });
  }

  sendObservation(mail: string, obs: Observation): Observable<any> {
    if (obs.id !== null) { return; }
    let args = {
      // cmd: (obs.id=== null?`newobs`:`modobsapp`), // SHOULD NOT HAPPEN 'modobsapp'
      cmd: `newobs`,
      mai: `${mail}`,
      ido: `${obs.id}`,
      nam: `${obs.name}`,
      bus: `${obs.business}`,
      add: `${obs.address}`,
      zip: `${obs.zip}`,
      cit: `${obs.city}`,
      lig: `${obs.lights}`,
      dat: `${obs.date}`,
      com: `${obs.com}`,
      lat: `${obs.lat}`,
      lon: `${obs.lon}`,
      nbp: `${obs.pictures.length}`
    }
    for (let k = 0; k < obs.pictures.length; k++) {
      args[`p${k}`] = obs.pictures[k].photo;
    }
    return this.getJSON(args);
  }

  sendPhaseTwo(obs: Observation): Observable<any> {
    console.log(obs);
    let args = {
      cmd: `modobsphase2`,
      ido: `${obs.id}`,
      dat: `${obs.date_p2}`,
      rct: `${obs.reaction}`,
      rsn: `${obs.reason}`,
      fly: `${(obs.flyer?1:0)}`
    }
    return this.getJSON(args);
  }

  sendPhaseThree(obs: Observation): Observable<any> {
    let pics = obs.pictures.filter((elem) => elem.phase == 3);
    let args = {
      cmd: `modobsphase3`,
      ido: `${obs.id}`,
      dat: `${obs.date_p3}`,
      com: `${obs.com_p3}`,
      off: `${obs.lights_p3}`,
      nbp: `${pics.length}`
    }
    for (let k = 0; k < pics.length; k++) {
      args[`p${k}`] = pics[k].photo;
    }
    return this.getJSON(args);
  }

  // sendFlyer(email: string, com: string) {
  sendFlyer(email: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded');

    // let url = `${this.baseurl}/flyer_mail.php?email=${email}&com=${com}`;
    let url = `${this.baseurl}/flyer_mail.php?email=${email}`;
    this.http.get(url, {'headers': headers}).subscribe((data) => {console.log(data)});
  }

}

import { Injectable } from '@angular/core';
import { SurveyOption } from './../interfaces/survey-option';
import { Events } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyOptionsService {

  private buisinesses: SurveyOption[] = [];
  private lightings: SurveyOption[] = [];
  private reactions: SurveyOption[] = [];

  constructor(
      private evts: Events
  ) {
    this.evts.subscribe('commerces', (data) => {
      if (data.ok) {
          this.buisinesses = data.data;
      }
    })

    this.evts.subscribe('eclairages', (data) => {
      if (data.ok) {
          this.lightings = data.data;
      }
    })

    this.evts.subscribe('reactions', (data) => {
      if (data.ok) {
          this.reactions = data.data;
      }
    })
  }

  public getBusinessTypes() : SurveyOption[] {
      return this.buisinesses;
  }

  public getLightingTypes() : SurveyOption[] {
    return this.lightings;
  }

  public getReactionTypes() : SurveyOption[] {
    return this.reactions;
  }
}

import { Injectable } from '@angular/core';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

@Injectable({
  providedIn: 'root'
})
export class GPSService {

  private on: boolean = false;
  public lat: number = 0;
  public lon: number = 0;

  public mapbbox = null;

  constructor(public geolocation: Geolocation) {}

  public status() {
    return this.on;
  }

  public refresh() {
    this.updatePosition();
  }

  public updatePosition() {
    return new Promise((resolve) => {
      this.geolocation.getCurrentPosition().then((resp) => {
        this.on = true;
        this.lat = resp.coords.latitude;
        this.lon = resp.coords.longitude;
        resolve(true);
       }).catch((error) => {
        this.on = false;
        resolve(false);
       })
    });
  }

  public check(lat1, lon1, lat2, lon2, r) {
    let R = 6371; //radius of Earth (KM)
    let p = 0.017453292519943295;  //Pi/180
    let a = 0.5 - Math.cos((lat2-lat1)*p)/2 + Math.cos(lat1*p)*Math.cos(lat2*p) * (1-Math.cos((lon2-lon1)*p)) / 2;
    let d = 2 * r * Math.asin(Math.sqrt(a)); // 2*R*asin

    // r is in m
    return d <= (r / 1000.0)
  }
}
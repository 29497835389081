import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { APIService } from './services/api.service';
import { GPSService } from './services/gps.service';
import { SurveyOptionsService } from './services/survey-options.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private api: APIService,
    private sopts: SurveyOptionsService,
    public gps: GPSService
  ) {}

  ngOnInit() {
    this.router.navigate([''], {})
    // sessionStorage.clear();

    let _this = this;
    window.addEventListener('focus', () => { _this.focus(); } );
  }

  public focus() {
    this.gps.refresh();
  }
}

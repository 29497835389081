import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'observations',
    loadChildren: () => import('./pages/observations/observations.module').then(m => m.ObservationsPageModule)
  },
  {
    path: 'add-observation',
    loadChildren: () => import('./pages/add-observation/add-observation.module').then( m => m.AddObservationPageModule)
  },
  {
    path: 'modify-observation',
    loadChildren: () => import('./pages/modify-observation/modify-observation.module').then( m => m.ModifyObservationPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'infos',
    loadChildren: () => import('./pages/infos/infos.module').then( m => m.InfosPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
